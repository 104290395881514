<template>
  <section class="section is-custom-padding">
    <div>
      <div class="container">
        <div
          class="box has-text-right"
          v-bind:class="{
            'has-background-success-light boxUp': WarningObject.thumbsUp,
            'has-background-danger-light boxDown': WarningObject.thumbsDown,
            'has-background-info-light boxInfo': !WarningObject.thumbsUp,
          }"
        >
          <!-- <b-button v-if="GetCurrentUser.permissions.alertsremoving && !WarningObject.thumbsUp" class="is-floating-left" size="is-small" type="is-danger" icon-right="thumbs-down" @click="RemoveWarning" rounded inverted />
                <b-button v-if="GetCurrentUser.permissions.alertsremoving" class="is-floating-right" size="is-small" type="is-success" icon-right="thumbs-up" @click="MarkThumbsUpWarning()" rounded :inverted="!WarningObject.thumbsUp" :disabled="WarningObject.thumbsUp" />
                -->
          <div class="bts">
            <b-button
              size="is-small"
              class="mx-1"
              type="is-danger"
              icon-right="file-pdf"
              @click="pdfGenerate()"
              rounded
              focused
              inverted
            />
            <b-button
              size="is-small"
              class="mx-1"
              type="is-dark"
              icon-right="share-alt"
              @click="
                messageGenerate();
                isMessageWppModalActive = true;
              "
              rounded
              focused
              inverted
            />
            <b-button
              size="is-small"
              class="mx-1"
              :type="'comments' in WarningObject ? 'is-info' : 'is-dark'"
              icon-right="comment-alt"
              @click="comments = !comments"
              rounded
              focused
              inverted
            />
            <b-button
              size="is-small"
              class="mx-1"
              type="is-danger"
              icon-right="thumbs-down"
              @click="MarkThumbsDownWarning"
              rounded
              :focused="!WarningObject.thumbsDown"
              :disabled="WarningObject.thumbsDown"
              :inverted="!WarningObject.thumbsDown"
            />
            <b-button
              size="is-small"
              class="mx-1"
              type="is-success"
              icon-right="thumbs-up"
              @click="MarkThumbsUpWarning()"
              rounded
              :focused="!WarningObject.thumbsUp"
              :disabled="WarningObject.thumbsUp"
              :inverted="!WarningObject.thumbsUp"
            />
            <b-button
              size="is-small"
              class="mx-1"
              type="is-danger"
              icon-right="trash"
              @click="RemoveWarning"
              rounded
              focused
              inverted
            />
          </div>

          <div class="columns mt-1">
            <div class="column is-6 has-text-left">
              <div>
                <div class="block">
                  <b-icon
                    pack="fas"
                    v-for="(warningData, index) in GetWarningDataObject"
                    v-bind:key="index"
                    :icon="warningData.icon"
                    type="is-primary"
                    size="is-medium"
                  >
                  </b-icon>
                </div>
              </div>
              <p
                class="has-text-weight-bold is-size-5 is-size-7-mobile has-text-left-mobile"
              >
                <span
                  v-for="(warningData, index) in GetWarningDataObject"
                  v-bind:key="index"
                >
                  {{ warningData.ocurrencies }}
                  {{ warningData.type }}
                  <span v-if="index != GetWarningDataObject.length - 1">{{
                    $t("warnings.card.conjunction")
                  }}</span>
                  <!-- Does not show letter "e" when it's the last element. -->
                </span>

                {{ $tc("warnings.card.detected", GetWarningTotalOcurrencies) }}
              </p>
              <p
                class="has-text-weight-bold is-size-6 is-size-7-mobile has-text-left-mobile"
              >
                {{ presetRegion ? presetRegion : $t("warnings.card.region") }}
              </p>
              <h2 class="is-size-7">
                <p>ID: {{ WarningObject.datetime }}</p>
                <!-- <span v-if="WarningObject.lla.name">{{ $t('warnings.card.point') }}: {{ WarningObject.lla.name }}</span> -->
              </h2>
            </div>

            <div class="column is-6 has-text-left-mobile">
              <figure class="image is-pointer-cursor" @click="ShowHistoryModal">
                <img class="is-rounded" :src="GetImageDownloadLink" />
                <b-loading
                  :is-full-page="false"
                  :active.sync="IsImageLoading"
                  :can-cancel="true"
                ></b-loading>
              </figure>
            </div>
          </div>
          <div>
            <b-table class="has-text-left" :data="[WarningObject]">
              <b-table-column field="WarningObject.datetime" label="Data"
                ><span class="is-size-7-mobile">{{
                  new Date(WarningObject.datetime).toLocaleDateString(
                    GetLocaleChange
                  )
                }}</span></b-table-column
              >
              <b-table-column field="WarningObject.datetime" label="Hora"
                ><span class="is-size-7-mobile">{{
                  new Date(WarningObject.datetime).toLocaleTimeString(
                    GetLocaleChange
                  )
                }}</span></b-table-column
              >
              <b-table-column
                field="WarningObject.preset"
                label="Preset"
                v-if="WarningObject.preset"
                ><span class="is-size-7-mobile">
                  {{ WarningObject.preset }}
                </span>
              </b-table-column>
              <b-table-column
                field="WarningObject.lla.latidude"
                label="Latitude"
              >
                <span class="is-size-7-mobile">{{
                  WarningObject.lla.latitude
                }}</span>
              </b-table-column>
              <b-table-column
                field="WarningObject.lla.longitude"
                label="Longitude"
              >
                <span class="is-size-7-mobile">{{
                  WarningObject.lla.longitude
                }}</span>
              </b-table-column>
              <b-table-column field="Maps" label="Link externo">
                <span v-if="WarningObject.lla" class="is-size-7-mobile"
                  ><a
                    @click="OpenedExternalLink()"
                    :href="
                      'https://www.google.com/maps/search/?api=1&query=' +
                      WarningObject.lla.latitude +
                      ',' +
                      WarningObject.lla.longitude
                    "
                    target="_blank"
                    >Abrir no Google Maps
                    <b-icon pack="fas" icon="external-link-alt" size="is-small">
                    </b-icon></a
                ></span>

                <span class="is-size-7-mobile" v-else>-</span>
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
      <!-- modal image alert -->
      <b-modal
        :active.sync="IsWarningHistoryModalVisible"
        full-screen
        has-modal-card
      >
        <div class="centered" v-if="!fullImg">
          <div class="columns is-multiline is-gapless">
            <div @click="expandedImage" class="column is-half">
              <figure class="image">
                <img :src="GetImageDownloadLink" />
              </figure>
            </div>
            <div class="column is-half">
              <div class="is-100-percent-height">
                <CardWarningDetailsModal :WarningLocation="WarningObject.lla" />
              </div>
            </div>
          </div>
        </div>
        <div @click="expandedImage" class="is-100-percent-height" v-else>
          <figure
            class="image figure-full-screen-wide"
            v-if="GetCurrentUser.activeFlag == 'Altave'"
          >
            <img :src="GetImageDownloadLink" />
          </figure>
          <figure class="image figure-full-screen-4by3" v-else>
            <img :src="GetImageDownloadLink" />
          </figure>
        </div>
      </b-modal>
      <!-- modal message wpp -->
      <b-modal
        v-model="isMessageWppModalActive"
        :width="640"
        scroll="keep"
        class="whatsappModal"
        has-modal-card
      >
        <div class="card">
          <div class="box">
            <div class="card-image">
              <figure>
                <img :src="GetImageDownloadLink" />
                <b-button
                  @click="downloadImage"
                  class="btn-high-left"
                  icon-left="download"
                  type="is-primary"
                  rounded
                  inverted
                  outlined
                  size="is-small"
                />
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Report</p>
                </div>
              </div>

              <div class="content">
                <textarea
                  readonly
                  class="is-custom-height"
                  ref="generatedReport"
                  v-model="reportMessage"
                ></textarea>
              </div>
              <div class="content">
                <b-button class="mr-4" @click="CopyGeneratedReportToClipboard()"
                  >Copy</b-button
                >
                <b-button
                  v-if="this.$isMobile()"
                  type="is-success"
                  tag="a"
                  :href="
                    'whatsapp://send?text=' +
                    encodeURIComponent(this.reportMessage)
                  "
                  target="_blank"
                  >Share</b-button
                >
                <b-button
                  v-else
                  type="is-success"
                  tag="a"
                  :href="
                    'https://api.whatsapp.com/send?text=' +
                    encodeURIComponent(this.reportMessage)
                  "
                  target="_blank"
                  >Share</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <!-- modal comments -->
      <b-modal v-model="comments">
        <div class="commentsCard">
          <header class="modal-card-head">
            <b>ID: </b>{{ WarningObject.datetime }}
          </header>
          <div class="modal-card-body">
            <b-table
              :data="WarningObject.comments"
              height="265"
              striped
              hoverable
              focusable
              narrowed
              sticky-header
            >
              <b-table-column
                field="timestamp"
                label="Data"
                v-slot="props"
                centered
                width="40"
              >
                {{ new Date(props.row.timestamp).toLocaleString("pt-br") }}
              </b-table-column>
              <b-table-column
                field="comment"
                label="Observações"
                width="400"
                v-slot="props"
                centered
              >
                {{ props.row.comment }}
              </b-table-column>
              <b-table-column label="Autor" width="70" v-slot="props" centered>
                {{ props.row.user }}
              </b-table-column>
              <b-table-column
                label="Empresa"
                width="50"
                v-slot="props"
                centered
              >
                {{ capitalizeFirstLetter(props.row.company) }}
              </b-table-column>
            </b-table>
          </div>

          <div class="footerComment">
            <b-field expanded class="commentInsert">
              <b-input
                type="search"
                expanded
                class="ml-3 mt-3"
                custom-class="is-large"
                rounded
                v-model="comment"
              ></b-input>
              <p class="control">
                <b-button
                  class="button is-primary mr-3 mt-3 is-large"
                  rounded
                  icon-right="paper-plane"
                  @click="insertComment()"
                />
              </p>
            </b-field>
          </div>
        </div>
      </b-modal>
    </div>
  </section>
</template>
<style lang="scss">
.footerComment {
  background: white;
  min-height: 10vh;
}
.commentInsert {
  background: lightgray;
  min-height: 10.5vh;
}
.is-custom-height {
  width: 100%;
  height: 25vh;
  font-size: larger;
}
.centered {
  margin-top: 8%;
}
.container.is-overflow {
  overflow-x: auto;
}
.figure-full-screen-wide {
  width: 134vh;
  // width: 175vh;

  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.figure-full-screen-4by3 {
  width: 175vh;

  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.is-100-percent-height {
  height: 100%;
}
.is-pointer-cursor {
  cursor: pointer;
}
.section.is-custom-padding {
  padding-top: 0;
}
.image img.is-rounded {
  border-radius: 12px !important;
}
.bts {
  float: right;
  margin-top: -2.5em;
  margin-right: -1.2em;
  display: inline-block;
}
.btn-high-left {
  position: absolute;
  bottom: 10px;
  right: 2px;
}
.whatsappModal {
  margin-top: -25%;
}
.commentsCard {
  min-height: 49vh;
  max-height: 70vh;
  overflow: auto;
}
.boxInfo {
  border-color: blue;
  border-style: solid;
  border-width: 0.05vh;
}
.boxUp {
  border-color: green;
  border-style: solid;
  border-width: 0.05vh;
}
.boxDown {
  border-color: red;
  border-style: solid;
  border-width: 0.05vh;
}
.button[disabled] {
  opacity: 100;
}
</style>
<script>
import CardWarningDetailsModal from "@/components/warning/CardWarningDetailsModal.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
import firebase from "../../../firebase.config";

export default {
  name: "MordorWarnings",
  components: {
    CardWarningDetailsModal,
  },
  props: {
    WarningObject: Object,
  },
  data() {
    return {
      IsWarningHistoryModalVisible: false,
      ImageDownloadLink: "",
      IsImageLoading: true,
      fullImg: true,
      isMessageWppModalActive: false,
      reportMessage: "",
      comments: false,
      comment: "",
      presetRegion: "",
    };
  },
  computed: {
    GetCurrentUser() {
      return this.$store.getters["user/GetCurrentUser"];
    },
    GetWarningDataObject() {
      let warningDataObject = [];
      for (let index in this.WarningObject.alerts) {
        switch (index) {
          case "1":
            if (this.WarningObject.alerts[index] > 0) {
              warningDataObject.push({
                type: this.$i18n.tc(
                  "warnings.card.types.person",
                  this.WarningObject.alerts[index]
                ),
                ocurrencies: this.WarningObject.alerts[index],
                icon: "running",
              });
            }
            break;
          case "2":
            if (this.WarningObject.alerts[index] > 0) {
              warningDataObject.push({
                type: this.$i18n.tc(
                  "warnings.card.types.vehicle",
                  this.WarningObject.alerts[index]
                ),
                ocurrencies: this.WarningObject.alerts[index],
                icon: "car",
              });
            }
            break;
          case "3":
            if (this.WarningObject.alerts[index] > 0) {
              warningDataObject.push({
                type: this.$i18n.tc(
                  "warnings.card.types.interest",
                  this.WarningObject.alerts[index]
                ),
                ocurrencies: this.WarningObject.alerts[index],
                icon: "map-marker-alt",
              });
            }
            break;
          case "4":
            if (this.WarningObject.alerts[index] > 0) {
              warningDataObject.push({
                type: this.$i18n.tc(
                  "warnings.card.types.hotPoint",
                  this.WarningObject.alerts[index]
                ),
                ocurrencies: this.WarningObject.alerts[index],
                icon: "thermometer-full",
              });
            }
            break;
          case "5":
            if (this.WarningObject.alerts[index] > 0) {
              warningDataObject.push({
                type: this.$i18n.tc(
                  "warnings.card.types.light",
                  this.WarningObject.alerts[index]
                ),
                ocurrencies: this.WarningObject.alerts[index],
                icon: "bolt",
              });
            }
            break;
          case "6":
            if (this.WarningObject.alerts[index] > 0) {
              warningDataObject.push({
                type: this.$i18n.tc(
                  "warnings.card.types.boat",
                  this.WarningObject.alerts[index]
                ),
                ocurrencies: this.WarningObject.alerts[index],
                icon: "ship",
              });
            }
            break;
          case "7":
            if (this.WarningObject.alerts[index] > 0) {
              warningDataObject.push({
                type: this.$i18n.tc(
                  "warnings.card.types.fire",
                  this.WarningObject.alerts[index]
                ),
                ocurrencies: this.WarningObject.alerts[index],
                icon: "fire",
              });
            }
            break;
        }
      }
      return warningDataObject;
    },
    GetWarningTotalOcurrencies() {
      let totalWarningOcurrencies = 0;
      for (let index in this.WarningObject.alerts) {
        totalWarningOcurrencies += this.WarningObject.alerts[index];
      }
      return totalWarningOcurrencies;
    },
    GetImageDownloadLink() {
      return this.ImageDownloadLink;
    },
    GetLocaleChange() {
      return this.$i18n.locale;
    },
    GetSelectedCam() {
      return this.$store.getters["database/GetCurrentCamera"];
    },
  },
  watch: {
    GetImageDownloadLink: {
      handler(url) {
        if (url) this.IsImageLoading = false;
      },
    },
  },
  methods: {
    pdfGenerate() {
      try {
        const doc = new jsPDF({
          orientation: "p",
          unit: "mm",
          format: "a4",
          putOnlyUsedFonts: true,
          floatPrecision: 16,
        });

        let data = new Date(Date.now()).toLocaleDateString(
          this.GetLocaleChange
        );
        doc.setFontSize(15);
        doc.setFontStyle("bold");
        doc.text(data, 10, 20);
        doc.text("Alerta", 100, 20, "center");

        let img = new Image();
        img.src = require("@/assets/harpia-color.png");
        //(imageData, format, x, y, width, height, alias, compression, rotation)
        doc.addImage(img, "PNG", 170, 12, 30, 10);

        let imgWarning = new Image();
        imgWarning.src = this.GetImageDownloadLink;
        //(imageData, format, x, y, width, height, alias, compression, rotation)
        doc.addImage(imgWarning, "PNG", 10, 30, 190, 110);

        doc.setFontSize(10); //Set font size of next lines.
        doc.setFontStyle("bold");
        doc.text("ID: ", 10, 150, "left"); //Print text.
        doc.setFontStyle("normal");
        doc.text(
          this.getWarningID(),
          doc.getTextWidth("ID: ") + 10,
          150,
          "left"
        );

        doc.setFontStyle("bold");
        doc.text("Data: ", 10, 155, "left"); //Print text.
        doc.setFontStyle("normal");
        doc.text(
          this.getWarningDate() + " - " + this.getWarningTime(),
          doc.getTextWidth("Data: ") + 10,
          155,
          "left"
        );

        doc.setFontStyle("bold");
        doc.text("Alerta: ", 10, 160, "left");
        doc.setFontStyle("normal");
        doc.text(
          this.getWarningsOcurrencies(),
          doc.getTextWidth("Alerta: ") + 11,
          160,
          "left"
        );

        let camera = this.getWarningCamera();
        let latitude = this.getWarningLatitude().toString();
        let longitude = this.getWarningLongitude().toString();
        if (camera) {
          doc.setFontStyle("bold");
          doc.text("Camera: ", 10, 165, "left");
          doc.setFontStyle("normal");
          doc.text(camera, doc.getTextWidth("Camera: ") + 12, 165, "left");
        }
        if (latitude && longitude) {
          doc.setFontStyle("bold");
          doc.text("Latitude: ", 10, 170, "left");
          doc.setFontStyle("normal");
          doc.text(latitude, doc.getTextWidth("Latitude: ") + 12, 170, "left");

          doc.setFontStyle("bold");
          doc.text("Longitude: ", 10, 175, "left");
          doc.setFontStyle("normal");
          doc.text(
            longitude,
            doc.getTextWidth("Longitude: ") + 12,
            175,
            "left"
          );
        }

        const rows = this.convertCommentsToTable(this.WarningObject.comments);
        if (rows.length) {
          const columns = ["Data", "Observações", "Autor", "Empresa"];
          doc.autoTable(columns, rows, {
            rowPageBreak: "avoid",
            startY: 180,
            tableWidth: 190,
            margin: { left: 10 },
            styles: {
              valign: "middle",
              overflow: "linebreak",
              cellWidth: "wrap",
            },
            columnStyles: {
              0: {
                cellWidth: 25,
              },
              1: {
                cellWidth: 110,
              },
              2: {
                cellWidth: "auto",
              },
              3: {
                cellWidth: "auto",
              },
            },
          }); //Transforms given columns and rows array in a table.)
        }

        doc.save("Alerta (" + this.WarningObject.datetime + ").pdf"); //Saves created table to PDF.
      } catch (error) {
        this.LaunchToast(
          "Não há dados suficientes para gerar relatório!" + error,
          "is-danger"
        );
      }
    },
    convertCommentsToTable(comments) {
      let rows = []; //Create empty array to house newly created rows.
      try {
        Object.entries(comments).forEach((comment) => {
          let tempArray = [
            new Date(comment[1].timestamp).toLocaleString("pt-br"),
            comment[1].comment,
            comment[1].user,
            this.capitalizeFirstLetter(comment[1].company),
          ];
          rows.push(tempArray);
        });
      } catch (err) {
        return err;
      }
      return rows;
    },
    getWarningsOcurrencies() {
      let warnings = "";
      this.GetWarningDataObject.forEach((element, i) => {
        warnings += element.ocurrencies + " " + element.type;
        if (i != this.GetWarningDataObject.length - 1) {
          warnings += " e ";
        }
      });
      return warnings;
    },
    getWarningID() {
      return this.WarningObject.datetime.toString();
    },
    getWarningDate() {
      return new Date(this.WarningObject.datetime).toLocaleDateString("pt-br");
    },
    getWarningTime() {
      return new Date(this.WarningObject.datetime).toLocaleTimeString("pt-br");
    },
    getWarningLatitude() {
      try {
        return this.WarningObject.lla.latitude;
      } catch {
        console.log("Sem latitude");
      }
    },
    getWarningLongitude() {
      try {
        return this.WarningObject.lla.longitude;
      } catch {
        console.log("Sem longitude");
      }
    },
    getWarningCamera() {
      try {
        return this.WarningObject.camera;
      } catch {
        console.log("Sem camera");
      }
    },
    messageGenerate() {
      let warnings = this.getWarningsOcurrencies();
      let idx = this.getWarningID();
      let date = this.getWarningDate();
      let time = this.getWarningTime();
      let latitude = this.getWarningLatitude();
      let longitude = this.getWarningLongitude();
      let camera = this.getWarningCamera();

      this.reportMessage = `*ID:* ${idx}\n*Alertas:* ${warnings}\n*Data:* ${date}\n*Hora:* ${time}\n`;

      if (camera) {
        this.reportMessage += `*Camera:* ${camera}\n`;
      }

      if (latitude && longitude) {
        var linkMaps =
          "https://www.google.com/maps/search/?api=1&query=" +
          latitude +
          "," +
          longitude;
        this.reportMessage += `*Latitude:* ${latitude}\n*Longitude:* ${longitude}\n*Link:* ${linkMaps}`;
      }
    },
    expandedImage() {
      this.fullImg = !this.fullImg;
    },
    ShowHistoryModal() {
      this.MarkSeenWarning();
      this.IsWarningHistoryModalVisible = true;
      this.fullImg = false;
      this.$gtag.event("open_warning_image", { event_category: "warnings" });
    },
    RetrieveImageDownloadLink() {
      let warningDateString = new Date(this.WarningObject.datetime)
        .toLocaleDateString("pt-BR")
        .split("/")
        .join("-");
      let userFlag = this.GetCurrentUser.activeFlag.toLowerCase();
      this.$store
        .dispatch(
          "storage/GetSauronImageDownloadLink",
          userFlag +
            "-backlog/" +
            warningDateString +
            "/" +
            this.WarningObject.key.replace(
              "ACCC8ED12FB7",
              this.WarningObject.idx
            )
        )
        .then((url) => {
          this.ImageDownloadLink = url;
        })
        .catch(() => {
          this.$store
            .dispatch(
              "storage/GetSauronImageDownloadLink",
              userFlag +
                "/" +
                this.WarningObject.camera +
                "/" +
                warningDateString +
                "/" +
                this.WarningObject.key
            )
            .then((url) => {
              this.ImageDownloadLink = url;
            })
            .catch(() => {
              this.$store
                .dispatch(
                  "storage/GetSauronImageDownloadLink",
                  userFlag +
                    "-backlog/" +
                    warningDateString +
                    "/" +
                    this.WarningObject.key.replace("noidx", "ACCC8ED12FB7")
                )
                .then((url) => {
                  this.ImageDownloadLink = url;
                })
                .catch(() => {
                  this.$store
                    .dispatch(
                      "storage/GetSauronImageDownloadLink",
                      userFlag +
                        "-backlog/" +
                        this.WarningObject.camera +
                        "/" +
                        warningDateString +
                        "/" +
                        this.WarningObject.key
                    )
                    .then((url) => {
                      this.ImageDownloadLink = url;
                    });
                });
            });
        });
    },
    RemoveWarning() {
      this.$buefy.dialog.confirm({
        title: "Remover alerta",
        message:
          "Tem certeza de que deseja remover o alerta <b>" +
          this.WarningObject.datetime +
          "</b>?",
        confirmText: "Confirmar remoção",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          let warningDateString = new Date(this.WarningObject.datetime)
            .toLocaleDateString("pt-BR")
            .split("/")
            .join("-");
          this.WarningObject.whoRemoved = this.GetCurrentUser.email;
          this.WarningObject.idxname;
          if (
            this.WarningObject.idx == "noidx" ||
            this.WarningObject.idx.startsWith("minipop")
          ) {
            this.WarningObject.idx = this.WarningObject.key;
          }
          this.comment = "Alerta desaprovado";
          this.insertComment().then(() => {
            this.$store
              .dispatch("database/RemoveWarningFromDatabase", {
                warning: this.WarningObject,
                warningId: this.WarningObject.idx,
                date: warningDateString,
              })
              .then(() => {
                this.$gtag.event("click_warning_dislike", {
                  event_category: "warnings",
                });
                if (this.WarningObject.seen) this.$emit("SeenWarningRemoved");
                else this.$emit("UnseenWarningRemoved");
              });
          });
        },
      });
    },
    MarkSeenWarning() {
      //TODO: MERGE WITH MARKTHUMBSUPWARNING.
      if (this.WarningObject.seen) return;
      let warningDateString = new Date(this.WarningObject.datetime)
        .toLocaleDateString("pt-BR")
        .split("/")
        .join("-");
      let warningIdxName = this.WarningObject.idxname
        .toLowerCase()
        .replace("-backlog", "");
      let warningIdx = this.WarningObject.idx;
      let warningId = this.WarningObject.key;
      let warningCamera = this.WarningObject.camera;
      this.$store
        .dispatch("database/MarkSeenWarning", {
          warningDateString: warningDateString,
          warningIdxName: warningIdxName + "/" + warningCamera,
          warningIdx: warningIdx,
          warningId: warningId,
        })
        .then(() => {
          this.$emit("WarningSeen");
        });
    },
    MarkThumbsUpWarning() {
      if (this.WarningObject.thumbsUp) return;

      let warningDateString = new Date(this.WarningObject.datetime)
        .toLocaleDateString("pt-BR")
        .split("/")
        .join("-");
      let warningIdxName = this.WarningObject.idxname
        .toLowerCase()
        .replace("-backlog", "");
      let warningIdx = this.WarningObject.idx;
      let warningId = this.WarningObject.key;
      let approvedBy = {
        name: this.GetCurrentUser.name,
        email: this.GetCurrentUser.email,
      };
      let warningCamera = this.WarningObject.camera;

      if (this.WarningObject.thumbsDown) {
        this.$store.dispatch("database/RemoveFromDatabase", {
          path:
            "sauron/" +
            warningIdxName +
            "/" +
            warningCamera +
            "/" +
            warningDateString +
            "/" +
            warningId +
            "/thumbsDown",
        });
        this.WarningObject.thumbsDown = false;
      }

      this.$store
        .dispatch("database/MarkThumbsUpWarning", {
          warningDateString: warningDateString,
          warningIdxName: warningIdxName + "/" + warningCamera,
          warningIdx: warningIdx,
          warningId: warningId,
          approvedBy: approvedBy,
        })
        .then(() => {
          this.WarningObject.thumbsUp = true;
          this.fullImg = false;
          this.comment = "Alerta Aprovado";
          this.insertComment();
        });
    },
    MarkThumbsDownWarning() {
      if (this.WarningObject.thumbsDown) return;
      let warningDateString = new Date(this.WarningObject.datetime)
        .toLocaleDateString("pt-BR")
        .split("/")
        .join("-");
      let warningIdxName = this.WarningObject.idxname
        .toLowerCase()
        .replace("-backlog", "");
      let warningIdx = this.WarningObject.idx;
      let warningId = this.WarningObject.key;
      let disapproveddBy = {
        name: this.GetCurrentUser.name,
        email: this.GetCurrentUser.email,
      };
      let warningCamera = this.WarningObject.camera;

      if (this.WarningObject.thumbsUp) {
        this.$store.dispatch("database/RemoveFromDatabase", {
          path:
            "sauron/" +
            warningIdxName +
            "/" +
            warningCamera +
            "/" +
            warningDateString +
            "/" +
            warningId +
            "/thumbsUp",
        });
        this.WarningObject.thumbsUp = false;
      }

      this.$store
        .dispatch("database/MarkThumbsDownWarning", {
          warningDateString: warningDateString,
          warningIdxName: warningIdxName + "/" + warningCamera,
          warningIdx: warningIdx,
          warningId: warningId,
          disapproveddBy: disapproveddBy,
        })
        .then(() => {
          this.WarningObject.thumbsDown = true;
          this.fullImg = false;
          this.comment = "Alerta desaprovado";
          this.insertComment();
        });
    },
    OpenedExternalLink() {
      this.$gtag.event("open_warning_external_link", {
        event_category: "warnings",
      });
    },
    LaunchToast(message, type) {
      this.$buefy.toast.open({
        //Displays toast with given properties below.
        message: message, //Message to display.
        type: type, //Toast's color.
        position: "is-bottom", //Toast's position.
        duration: 3000, //Toast's duration.
      });
    },
    CopyGeneratedReportToClipboard() {
      this.$refs.generatedReport.select();
      document.execCommand("copy");
    },
    downloadImage() {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function () {
        const blob = xhr.response;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = new Date().getTime() + ".jpg";
        link.click();
        URL.revokeObjectURL(link.href);
      };
      xhr.open("GET", this.GetImageDownloadLink);
      xhr.send();
    },
    CheckLike(status) {
      if (status) {
        let ref = firebase
          .database()
          .ref(
            "sauron/" +
              this.GetCurrentUser.activeFlag.toLowerCase() +
              "/" +
              this.WarningObject.camera +
              "/" +
              this.getWarningDate().replaceAll("/", "-") +
              "/" +
              this.WarningObject.key
          );
        ref.on("value", (snapshot) => {
          if (snapshot.val().thumbsUp) {
            this.WarningObject.thumbsUp = snapshot.val().thumbsUp;
            this.fullImg = false;
            this.WarningObject.thumbsDown = false;
          } else if (snapshot.val().thumbsDown) {
            this.WarningObject.thumbsDown = snapshot.val().thumbsDown;
            this.WarningObject.thumbsUp = false;
            this.fullImg = false;
          }
        });
      } else {
        let ref = firebase
          .database()
          .ref(
            "sauron/" +
              this.GetCurrentUser.activeFlag.toLowerCase() +
              "/" +
              this.WarningObject.camera +
              "/" +
              this.getWarningDate().replaceAll("/", "-") +
              "/" +
              this.WarningObject.key
          );
        ref.off();
      }
    },
    GetWarningLatitudeFromDB() {
      if (
        !this.WarningObject.lla ||
        this.WarningObject.lla.latitude == 1 ||
        this.WarningObject.lla.latitude == 1
      ) {
        this.$store
          .dispatch("database/GetFromDatabase", {
            path:
              "/sauron/" +
              this.GetCurrentUser.activeFlag.toLowerCase() +
              "/camcontrol/" +
              this.getWarningCamera() +
              "/presets/" +
              this.WarningObject.preset +
              "/coordenadas/" +
              this.WarningObject.preset +
              "/lat",
          })
          .then((snp) => {
            if (snp.exists()) {
              this.WarningObject.lla.latitude = snp.val();
              const alertDate = this.getWarningDate().split("/").join("-");
              if (!(alertDate instanceof Date)) {
                return;
              }
              this.$store.dispatch("database/SetOnDatabase", {
                path:
                  "/sauron/" +
                  this.GetCurrentUser.activeFlag.toLowerCase() +
                  "/" +
                  this.getWarningCamera() +
                  "/" +
                  this.getWarningDate().split("/").join("-") +
                  "/" +
                  this.WarningObject.key +
                  "/lla/latitude",
                object: Number(snp.val()),
              });
            }
          });
      }
    },
    GetWarningLongitudeFromDB() {
      if (!this.WarningObject.lla || this.WarningObject.lla.longitude == 1) {
        this.$store
          .dispatch("database/GetFromDatabase", {
            path:
              "/sauron/" +
              this.GetCurrentUser.activeFlag.toLowerCase() +
              "/camcontrol/" +
              this.getWarningCamera() +
              "/presets/" +
              this.WarningObject.preset +
              "/coordenadas/" +
              this.WarningObject.preset +
              "/long",
          })
          .then((snp) => {
            if (snp.exists()) {
              this.WarningObject.lla.longitude = snp.val();
              const alertDate = this.getWarningDate().split("/").join("-");
              if (!(alertDate instanceof Date)) {
                return;
              }
              this.$store.dispatch("database/SetOnDatabase", {
                path:
                  "/sauron/" +
                  this.GetCurrentUser.activeFlag.toLowerCase() +
                  "/" +
                  this.getWarningCamera() +
                  "/" +
                  this.getWarningDate().split("/").join("-") +
                  "/" +
                  this.WarningObject.key +
                  "/lla/longitude",
                object: Number(snp.val()),
              });
            }
          });
      }
    },

    insertComment() {
      let ts = Date.now();
      if (this.comment.length) {
        return this.$store
          .dispatch("database/SetOnDatabase", {
            path:
              "/sauron/" +
              this.GetCurrentUser.activeFlag.toLowerCase() +
              "/" +
              this.getWarningCamera() +
              "/" +
              this.getWarningDate().split("/").join("-") +
              "/" +
              this.WarningObject.key +
              "/comments/" +
              ts +
              "/",
            object: {
              user: this.GetCurrentUser.name,
              company: this.GetCurrentUser.company,
              comment: this.comment,
              timestamp: ts,
            },
          })
          .then(() => {
            return this.$store
              .dispatch("database/GetFromDatabase", {
                path:
                  "/sauron/" +
                  this.GetCurrentUser.activeFlag.toLowerCase() +
                  "/" +
                  this.getWarningCamera() +
                  "/" +
                  this.getWarningDate().split("/").join("-") +
                  "/" +
                  this.WarningObject.key +
                  "/comments/",
              })
              .then((snp) => {
                this.WarningObject.comments = snp.val();
                this.comment = "";
                this.LaunchToast(
                  "Observação inserida com sucesso",
                  "is-success"
                );
              })
              .catch((error) => {
                this.LaunchToast(error.message, "is-danger");
              });
          })
          .catch((error) => {
            this.LaunchToast(error.message, "is-danger");
          });
      } else {
        this.LaunchToast("Insira a observação.", "is-danger");
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    GetPresetRegionFromDB() {
      this.$store
        .dispatch("database/GetFromDatabase", {
          path:
            "/sauron/" +
            this.GetCurrentUser.activeFlag.toLowerCase() +
            "/camcontrol/" +
            this.getWarningCamera() +
            "/presets/" +
            this.WarningObject.preset +
            "/presetRegion",
        })
        .then((snp) => {
          if (snp.exists()) {
            this.presetRegion = snp.val();
          }
        });
    },
  },
  created() {
    this.WarningObject.lla = new Object();
    this.WarningObject.lla.latitude = 1;
    this.WarningObject.lla.longitude = 1;
    this.CheckLike(true);
    this.RetrieveImageDownloadLink();
    this.GetWarningLatitudeFromDB();
    this.GetWarningLongitudeFromDB();
    this.GetPresetRegionFromDB();
  },
  beforeDestroy() {
    this.CheckLike(false);
  },
};
</script>
