<template>
  <div>
    <TheNavbar />
    <section class="columns is-fullheight">
      <div class="column is-2 has-background-primary">
        <img
          :src="
            'https://ui-avatars.com/api/?rounded=true&size=240&name=' +
            GetNameInitials[0] +
            '+' +
            GetNameInitials[1] +
            '&background=0D8ABC&color=fff'
          "
        />
        <div class="container">
          <p class="has-text-weight-bold has-text-white is-size-5">
            {{ GetCurrentUser.name }}
          </p>
        </div>
        <br />
        <section class="section">
          <div class="columns is-multiline is-centered is-vcentered">
            <div
              class="mb-2 column has-vertical-gap-bottom has-rounded-corner is-full has-background-info-light"
            >
              <span
                class="has-text-weight-bold"
                v-if="FilteredAlertsCount > 0 && FilteredAlertsCount < 30"
                >{{ FilteredAlerts.length }}
                {{ $t("sauronAux.avaiblesImages") }}</span
              >
              <span
                class="has-text-weight-bold"
                v-else-if="FilteredAlertsCount > 29"
                >{{ FilteredAlerts.length }}+
                {{ $t("sauronAux.avaiblesImages") }}</span
              >
              <span class="has-text-weight-bold" v-else>{{
                $t("sauronAux.noImagesAvailable")
              }}</span>
            </div>
            <!-- Start db button -->
            <b-button
              v-if="!IsCurrentlyWorkingManual"
              @click="
                ToggleWorking();
                getAlerts();
              "
              class="mb-2 is-fullwidth"
              v-bind:type="{
                'is-danger': IsCurrentlyWorkingDatabase,
                'is-success': !IsCurrentlyWorkingDatabase,
              }"
              icon-left="database"
            >
              <span
                class="has-text-weight-bold"
                v-if="IsCurrentlyWorkingDatabase"
              >
                {{ $t("sauronAux.stop") }}
              </span>
              <span class="has-text-weight-bold" v-else>
                {{ $t("sauronAux.start") }}
              </span>
            </b-button>
            <!-- End db button -->

            <!-- Start manual buton-->
            <b-button
              v-if="!IsCurrentlyWorkingDatabase"
              @click="ToggleWorkingManual()"
              class="mb-2 is-fullwidth"
              v-bind:type="{
                'is-danger': IsCurrentlyWorkingManual,
                'is-success': !IsCurrentlyWorkingManual,
              }"
              icon-left="upload"
            >
              <span
                class="has-text-weight-bold"
                v-if="IsCurrentlyWorkingManual"
              >
                {{ $t("sauronAux.stop") }}
              </span>
              <span class="has-text-weight-bold" v-else>
                {{ $t("sauronAux.upload") }}
              </span>
            </b-button>
            <!-- End manual buttom -->
            <div
              v-if="
                (IsCurrentlyWorkingDatabase && this.FilteredAlertsCount >= 1) ||
                (IsCurrentlyWorkingManual && manualImage)
              "
              class="column is-full has-background-info-light"
            >
              <b-field>
                <b-select
                  @change.native="changeTarget"
                  :placeholder="$t('sauronAux.target')"
                >
                  <option :value="$t('sauronAux.person')">
                    {{ $t("sauronAux.person") }}
                  </option>
                  <option :value="$t('sauronAux.vehicle')">
                    {{ $t("sauronAux.vehicle") }}
                  </option>
                  <option :value="$t('sauronAux.interestPoint')">
                    {{ $t("sauronAux.interestPoint") }}
                  </option>
                  <option :value="$t('sauronAux.hotpoint')">
                    {{ $t("sauronAux.hotpoint") }}
                  </option>
                  <option :value="$t('sauronAux.light')">
                    {{ $t("sauronAux.light") }}
                  </option>
                  <option :value="$t('sauronAux.boat')">
                    {{ $t("sauronAux.boat") }}
                  </option>
                  <option :value="$t('sauronAux.fire')">
                    {{ $t("sauronAux.fire") }}
                  </option>
                  <option value="Redzone">Redzone</option>
                </b-select>
              </b-field>
              <b-button
                @click="save"
                class="mb-2 is-fullwidth"
                :label="$t('sauronAux.save')"
                type="is-primary"
                icon-left="save"
              />
              <b-button
                class="mb-2 is-fullwidth"
                @click="undo"
                :label="$t('sauronAux.undo')"
                type="is-warning"
                icon-left="undo"
              />
              <b-button
                class="mb-2 is-fullwidth"
                @click="clean()"
                :label="$t('sauronAux.clean')"
                type="is-danger"
                icon-left="eraser"
              />
              <b-button
                class="mb-2 is-fullwidth"
                @click="remove"
                :label="$t('sauronAux.remove')"
                type="is-danger"
                icon-left="trash"
              />
            </div>
          </div>
        </section>
      </div>

      <!-- Start db canvas -->
      <section
        class="column is-parent-full-height is-flex is-align-items-center is-justify-content-center"
        v-bind:class="{ 'has-background-info': !IsCurrentlyWorkingDatabase }"
        v-if="!IsCurrentlyWorkingManual"
      >
        <div
          v-if="IsCurrentlyWorkingDatabase && this.FilteredAlertsCount >= 1"
          class="box"
          style="position: relative"
        >
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            :can-cancel="false"
          ></b-loading>
          <v-stage
            ref="stage"
            :config="stageSize"
            @mousemove="handleMouseMove"
            @mouseDown="handleMouseDown"
            @mouseUp="handleMouseUp"
          >
            <v-layer ref="layer">
              <v-image
                :config="{
                  image: image,
                }"
              />
              <v-group>
                <v-rect
                  v-for="(rec, index) in recs"
                  :key="'rec' + index"
                  :config="{
                    x: Math.min(rec.startPointX, rec.startPointX + rec.width),
                    y: Math.min(rec.startPointY, rec.startPointY + rec.height),
                    width: Math.abs(rec.width),
                    height: Math.abs(rec.height),
                    stroke: rec.color,
                    strokeWidth: 4,
                    opacity: 0.8,
                  }"
                />
                <v-rect
                  v-for="(capback, index) in captionBack"
                  :key="'capback' + index"
                  :config="{
                    x: Math.min(capback.startPointX - 2, capback.x - 2),
                    y: Math.min(capback.startPointY - 7, capback.y - 7),
                    width: Math.abs(capback.width),
                    height: Math.abs(capback.height),
                    stroke: capback.color,
                    strokeWidth: 13,
                  }"
                />
                <v-text
                  v-for="(cap, index) in caption"
                  :key="'cap' + index"
                  :config="{
                    fontSize: 10,
                    fontFamily: 'sans-serif',
                    x: Math.min(cap.startX, cap.x),
                    y: Math.min(cap.startY, cap.y - 12),
                    text: cap.text,
                    fill: 'white',
                  }"
                />
              </v-group>
            </v-layer>
          </v-stage>
        </div>
        <div
          v-else-if="IsCurrentlyWorkingDatabase && this.allAlerts.length <= 1"
        >
          <div>
            <h1 class="title">
              {{ $t("sauronAux.wait") }}
            </h1>
          </div>
        </div>
        <div v-else>
          <div>
            <h1 class="title has-text-white">
              {{ $t("sauronAux.pressStart") }}
            </h1>
          </div>
        </div>
      </section>
      <!-- End db canvas -->

      <!-- Start manual canvas -->
      <section
        class="column is-parent-full-height is-flex is-align-items-center is-justify-content-center"
        v-bind:class="{ 'has-background-info': !IsCurrentlyWorkingManual }"
        v-else
      >
        <div
          v-if="IsCurrentlyWorkingManual && manualImage"
          class="box"
          style="position: relative"
        >
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            :can-cancel="false"
          ></b-loading>
          <v-stage
            ref="stage"
            :config="stageSize"
            @mousemove="handleMouseMove"
            @mouseDown="handleMouseDown"
            @mouseUp="handleMouseUp"
          >
            <v-layer ref="layer">
              <v-image
                :config="{
                  image: manualImage,
                }"
              />
              <v-group>
                <v-rect
                  v-for="(rec, index) in recs"
                  :key="'rec' + index"
                  :config="{
                    x: Math.min(rec.startPointX, rec.startPointX + rec.width),
                    y: Math.min(rec.startPointY, rec.startPointY + rec.height),
                    width: Math.abs(rec.width),
                    height: Math.abs(rec.height),
                    stroke: rec.color,
                    strokeWidth: 4,
                  }"
                />
                <v-rect
                  v-for="(capback, index) in captionBack"
                  :key="'capback' + index"
                  :config="{
                    x: Math.min(capback.startPointX - 2, capback.x - 2),
                    y: Math.min(capback.startPointY - 8, capback.y - 8),
                    width: Math.abs(capback.width),
                    height: Math.abs(capback.height),
                    stroke: capback.color,
                    strokeWidth: 15,
                  }"
                />
                <v-text
                  v-for="(cap, index) in caption"
                  :key="'cap' + index"
                  :config="{
                    fontSize: 12,
                    fontFamily: 'sans-serif',
                    x: Math.min(cap.startX, cap.x),
                    y: Math.min(cap.startY - 2, cap.y - 14),
                    text: cap.text,
                    fill: 'white',
                    letterSpacing: 0.2,
                  }"
                />
              </v-group>
            </v-layer>
          </v-stage>
        </div>
        <div v-else-if="IsCurrentlyWorkingManual && !manualImage">
          <div>
            <b-field>
              <b-upload
                v-model="manualFile"
                drag-drop
                accept="image/*, .jpg,.png,.bmp,.gif,.tif,.webp,.heic,.jpeg,.tiff,.heif"
              >
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <p>{{ $t("sauronAux.dropFile") }}</p>
                  </div>
                </section>
              </b-upload>
            </b-field>
          </div>
        </div>
      </section>
      <!-- End manual canvas -->

      <b-modal v-model="isManualWarningGenerator">
        <b-loading
          :is-full-page="true"
          v-model="isSendManualWarning"
          :can-cancel="false"
        ></b-loading>
        <ManualWarningGenerator
          :manualWarningObject="manualWarningObject"
          @handle-form-data="saveManualImageToDB"
        />
      </b-modal>
    </section>
  </div>
</template>

<script>
import firebase from "../../../firebase.config.js";

import TheNavbar from "@/components/global/TheNavbar.vue";
import ManualWarningGenerator from "@/components/sauron/ManualWarningGenerator.vue";

export default {
  name: "SauronAuxiliary",
  data() {
    return {
      IsCurrentlyWorkingDatabase: false,
      IsCurrentlyWorkingManual: false,
      scale: 1,
      stageSize: {
        width: 1280,
        height: 720,
      },
      isDrawing: false,
      recs: [],
      image: new Image(),
      caption: [],
      allAlerts: [],
      currentWarning: [],
      alerts: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0 },
      captionBack: [],
      isLoading: false,
      FilteredAlerts: [],
      FilteredAlertsCount: 0,
      manualFile: null,
      manualImage: null,
      isManualWarningGenerator: false,
      isSendManualWarning: false,
      manualWarningObject: {
        alerts: null,
        lla: { latitude: null, longitude: null },
        idx: "noidx",
        idxname: "",
        datetime: "",
      },
    };
  },
  components: {
    TheNavbar,
    ManualWarningGenerator,
  },
  computed: {
    GetCurrentUser() {
      return this.$store.getters["user/GetCurrentUser"];
    },
    GetNameInitials() {
      return this.GetCurrentUser.email.split("@")[0].split(".");
    },
    GetAvailableImages() {
      return this.$store.getters["sauron/GetAvailableImagesAuxiliary"].filter(
        (object) => {
          return object[".key"].split("-")[2] > 1608471525000;
        }
      ); //1608241573000
    },
    OperationHasSoundAlert() {
      const operationsWithSoundAlert = ["sabesp", "puerta_de_oro"];
      return operationsWithSoundAlert.includes(
        this.GetCurrentUser.activeFlag.toLowerCase()
      );
    },
  },
  watch: {
    GetCurrentUser: {
      handler() {
        this.$store
          .dispatch("sauron/unbindAvailableImagesAuxiliary")
          .then(() => {
            this.$store.dispatch(
              "sauron/bindAvailableImagesAuxiliary",
              this.GetCurrentUser
            );
          })
          .then(() => {
            this.IsCurrentlyWorkingDatabase = false;
          });
        if (this.IsCurrentlyWorkingManual) this.resetManualWarningObject();
      },
      deep: true,
    },
    GetAvailableImages: {
      handler(images) {
        this.FilteredAlerts = images;
        this.FilteredAlertsCount = this.FilteredAlerts.length;
      },
    },
    manualFile: {
      handler(image) {
        if (image) {
          let newImageUrl = URL.createObjectURL(image);
          let newImage = new Image();
          newImage.src = newImageUrl;
          newImage.crossOrigin = "anonymous";
          newImage.width = 1280;
          newImage.height = 720;

          this.manualImage = newImage;
        }
      },
    },
  },
  methods: {
    handleMouseDown() {
      this.isDrawing = true;
      const pos = this.$refs.stage.getNode().getPointerPosition();
      this.setCaps([
        ...this.caption,
        {
          text: this.capText.toLowerCase(),
          startY: pos.y - 12,
          startX: pos.x,
        },
      ]);
      this.setCapsBackground([
        ...this.captionBack,
        {
          startPointX: pos.x,
          startPointY: pos.y,
          width: this.capText.toLowerCase().replace(/\s/g, "").length * 7 + 1,
          height: 0,
          color: "black",
        },
      ]);
      this.setRecs([
        ...this.recs,
        {
          startPointX: pos.x,
          startPointY: pos.y,
          width: 0,
          height: 0,
          color: this.strokeColor,
        },
      ]);
    },
    handleMouseUp() {
      this.isDrawing = false;
      this.addAlert();
    },
    setRecs(element) {
      this.recs = element;
    },
    setCaps(element) {
      this.caption = element;
    },
    setCapsBackground(element) {
      this.captionBack = element;
    },
    addAlert() {
      switch (this.capText) {
        case "Person":
        case "Pessoa":
          this.alerts[1]++;
          break;
        case "Vehicle":
        case "Veículo":
          this.alerts[2]++;
          break;
        case "Interest point":
        case "Ponto de interesse":
          this.alerts[3]++;
          break;
        case "Hotpoint":
        case "Ponto quente":
          this.alerts[4]++;
          break;
        case "Light":
        case "Luz":
          this.alerts[5]++;
          break;
        case "Boat":
        case "Barco":
          this.alerts[6]++;
          break;
        case "Fire":
        case "Incêndio":
          this.alerts[7]++;
          break;
        case "Redzone":
          this.alerts[8]++;
          break;
      }
    },
    removeAlert(alert) {
      switch (alert) {
        case "Person":
        case "Pessoa":
          this.alerts[1]--;
          break;
        case "Vehicle":
        case "Veículo":
          this.alerts[2]--;
          break;
        case "Interest point":
        case "Ponto de interesse":
          this.alerts[3]--;
          break;
        case "Hotpoint":
        case "Ponto quente":
          this.alerts[4]--;
          break;
        case "Light":
        case "Luz":
          this.alerts[5]--;
          break;
        case "Boat":
        case "Barco":
          this.alerts[6]--;
          break;
        case "Fire":
        case "Incêndio":
          this.alerts[7]--;
          break;
        case "Redzone":
          this.alerts[8]--;
          break;
      }
    },
    handleMouseMove() {
      if (!this.isDrawing) {
        return;
      }
      const point = this.$refs.stage.getNode().getPointerPosition();

      let curRec = this.recs[this.recs.length - 1];
      curRec.width = point.x - curRec.startPointX;
      curRec.height = point.y - curRec.startPointY;

      let curCaption = this.caption[this.caption.length - 1];
      curCaption.x = point.x;
      curCaption.y = point.y;

      let curCaptionBack = this.captionBack[this.captionBack.length - 1];
      curCaptionBack.x = point.x;
      curCaptionBack.y = point.y;
    },
    undo() {
      let alertText = this.caption.pop();
      if (alertText) {
        this.removeAlert(alertText.text);
      }
      this.recs.pop();
      this.captionBack.pop();
    },
    clean() {
      this.recs = [];
      this.caption = [];
      this.captionBack = [];
      this.alerts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0 };
    },
    changeTarget(event) {
      this.capText = event.target.value;
      switch (this.capText) {
        case "Person":
        case "Pessoa":
          this.strokeColor = "#00ff00";
          break;
        case "Vehicle":
        case "Veículo":
          this.strokeColor = "#0F4C81";
          break;
        case "Interest point":
        case "Ponto de interesse":
          this.strokeColor = "yellow";
          break;
        case "Hotpoint":
        case "Ponto quente":
          this.strokeColor = "orange";
          break;
        case "Light":
        case "Luz":
          this.strokeColor = "maroon";
          break;
        case "Boat":
        case "Barco":
          this.strokeColor = "brown";
          break;
        case "Fire":
        case "Incêndio":
          this.strokeColor = "red";
          break;
        case "Redzone":
          this.strokeColor = "red";
          break;
      }
    },
    save() {
      this.IsCurrentlyWorkingManual
        ? this.saveManualImage()
        : this.saveDatabaseImage();
    },
    sendCanvasImageToDb(canvas, name, path, date) {
      canvas.toBlob(
        (blob) => {
          let warningData = {
            file: blob,
            name: name,
            path: "sauronimages3/" + path + "/" + date,
          };
          this.$store.dispatch(
            "storage/SendWarningImageToStorage",
            warningData
          );
        },
        "image/jpeg",
        0.95
      );
    },
    saveDatabaseImage() {
      this.isSendManualWarning = true;
      let name = this.getImageName() + ".jpg";
      let date = this.getImageDate();
      let alertObject = this.currentWarning;
      alertObject.alerts = this.alerts;
      this.clean();
      this.image = new Image();
      let canvas = document.getElementsByTagName("canvas")[0];

      let cam = this.getAlertCam(alertObject);
      let path = "";
      cam
        ? (path = this.getFlagName() + "/" + cam)
        : (path = this.getFlagName());

      this.sendCanvasImageToDb(canvas, name, path, date);
      this.sendWarningToDB(alertObject, path, date, this.getImageName())
        .then(() => {
          this.removeWarningFromDB();
        })
        .then(() => {
          this.getAlerts();
        });
    },
    saveManualImage() {
      this.manualWarningObject.alerts = this.alerts;
      this.isManualWarningGenerator = true;
    },
    saveManualImageToDB() {
      let canvas = document.getElementsByTagName("canvas")[0];
      let datetime = new Date().getTime();
      let date = new Date(datetime)
        .toLocaleDateString("pt-BR")
        .split("/")
        .join("-");
      let name = `sauron-${this.manualWarningObject.idx}-${datetime}`;

      let cam = this.getAlertCam(this.manualWarningObject);
      let path = "";
      cam
        ? (path = this.getFlagName() + "/" + cam)
        : (path = this.getFlagName());

      this.manualWarningObject.datetime = datetime;
      this.manualWarningObject.idxname = this.getFlagName();

      this.sendCanvasImageToDb(canvas, name + ".jpg", path, date);
      this.sendWarningToDB(this.manualWarningObject, path, date, name).then(
        () => {
          this.resetManualWork();
        }
      );
    },
    ToggleWorking() {
      this.IsCurrentlyWorkingDatabase = !this.IsCurrentlyWorkingDatabase;
    },
    ToggleWorkingManual() {
      this.removeManualImage();
      this.IsCurrentlyWorkingManual = !this.IsCurrentlyWorkingManual;
    },
    getAlerts() {
      if (this.FilteredAlerts.length < 1) return;
      this.isLoading = true;
      this.currentWarning = this.FilteredAlerts[this.FilteredAlerts.length - 1];
      this.getImageAlert();
    },
    getImageAlert() {
      let imgName = this.getImageName();
      let imgDate = this.getImageDate();
      let path = this.getFlagName() + "-auxiliar/" + imgDate + "/" + imgName;
      this.$store
        .dispatch("storage/GetMordorImageDownloadLink", path)
        .then((resp) => {
          if (resp instanceof Error) {
            this.LaunchToast(resp, "is-danger");
            this.removeWarningFromDB();
            this.ToggleWorking();
          } else {
            let newImage = new Image();
            newImage.src = resp;
            newImage.crossOrigin = "anonymous";
            newImage.width = 1280;
            newImage.height = 720;
            this.image = newImage;
            this.isLoading = false;
          }
        });
    },
    getImageName() {
      return this.currentWarning[".key"];
    },
    getImageDate() {
      let imgName = this.getImageName();
      let imgNameSplit = imgName.split("-");
      let imgTimestamp = parseInt(imgNameSplit[imgNameSplit.length - 1]);
      let imgDate = new Date(imgTimestamp).toLocaleDateString();
      imgDate = imgDate.replaceAll("/", "-");
      return imgDate;
    },
    getFlagName() {
      return this.GetCurrentUser.activeFlag.toLowerCase();
    },
    getAlertCam(alertObject) {
      let cam = "";
      try {
        cam = alertObject.camera;
      } catch {
        cam = undefined;
      }
      return cam;
    },
    LaunchToast(message, type) {
      this.$buefy.toast.open({
        //Displays toast with given properties below.
        message: "Error: " + message, //Message to display.
        type: type, //Toast's color.
        position: "is-bottom", //Toast's position.
        duration: 3000, //Toast's duration.
      });
    },
    removeWarningFromDB() {
      let databasePathRemove =
        "sauron/" +
        this.getFlagName() +
        "-auxiliar/" +
        this.currentWarning[".key"];
      return this.$store.dispatch("database/RemoveFromDatabase", {
        path: databasePathRemove,
      });
    },
    sendWarningToDB(alertObject, path, date, name) {
      let databasePathCount = "sauron/" + path + "/count/" + date;
      return this.$store
        .dispatch("database/SetOnDatabase", {
          path: "sauron/" + path + "/" + date + "/" + name,
          object: alertObject,
        })
        .then(() => {
          //Push new warning count to database.
          this.$store.dispatch("database/SetOnDatabase", {
            path: databasePathCount,
            object: firebase.database.ServerValue.increment(1),
          });
        })
        .then(() => {
          return this.$store.dispatch("database/SetOnDatabase", {
            path: "sauron/" + path + "/date-index/" + date,
            object: true,
          });
        })
        .then(() => {
          if (this.OperationHasSoundAlert && alertObject.soundAlert) {
            this.$store.dispatch("database/SetOnDatabase", {
              path:
                "sauron/" +
                this.GetCurrentUser.activeFlag.toLowerCase() +
                "/camcontrol/" +
                this.getAlertCam(alertObject) +
                "/alerta_sonoro",
              object: firebase.database.ServerValue.increment(1),
            });
          }
        });
    },
    remove() {
      this.IsCurrentlyWorkingManual
        ? this.removeManualImage()
        : this.removeDatabaseImage();
    },
    removeManualImage() {
      this.clearManualFileAndImage();
      this.clean();
    },
    removeDatabaseImage() {
      this.$buefy.dialog.confirm({
        title: "Remover alerta",
        message:
          "Tem certeza de que deseja remover o alerta <b>" +
          this.currentWarning[".key"] +
          "</b>?",
        confirmText: "Confirmar remoção",
        cancelText: "Cancelar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.clean();
          this.removeWarningFromDB().then(() => {
            this.getAlerts();
          });
        },
      });
    },
    resetManualWork() {
      this.clearManualFileAndImage();
      this.resetManualWarningObject();
      this.isSendManualWarning = false;
      this.isManualWarningGenerator = false;
      this.clean();
    },
    clearManualFileAndImage() {
      (this.manualFile = null), (this.manualImage = null);
    },
    resetManualWarningObject() {
      this.manualWarningObject = {
        alerts: null,
        lla: { latitude: null, longitude: null },
        idx: "noidx",
        idxname: "",
        datetime: "",
      };
    },
  },
  created() {
    this.$store.dispatch(
      "sauron/bindAvailableImagesAuxiliary",
      this.GetCurrentUser
    );
  },
  beforeDestroy() {
    this.IsCurrentlyWorkingDatabase = false;
    this.$store.dispatch("sauron/unbindAvailableImagesAuxiliary");
  },
};
</script>

<style>
.is-fullheight.columns {
  margin: 0 auto;
  height: calc(99.6vh - 3.25rem);
  max-height: 100%;
}
.column.has-rounded-corner {
  border-radius: 12px;
}
</style>
